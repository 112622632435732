<template>
  <div>
    <div class="reservation-result-wrap">
      <div class="reservation-result" data-aos="flip-right">
        <dl class="completed">
          <!-- 지원서 접수가 완료되었습니다. -->
          <dt>{{ $t('content.accelerating.academy.ApplyComplete.txt01') }}</dt>
          <!-- 정상적으로 지원서가 접수 되었습니다. -->
          <!-- 지원결과는 공모 지원 내역에서 확인할 수 있습니다. -->
          <dd class="desc">
            {{ $t('content.accelerating.academy.ApplyComplete.txt02') }}<br>
            {{ $t('content.accelerating.academy.ApplyComplete.txt03') }}
          </dd>
        </dl>
      </div>
    </div>

    <!-- 버튼 -->
    <div class="btn-wrap">
      <!-- 확인 -->
      <BaseButton
        @click="doNext"
      >{{ $t('content.accelerating.academy.ApplyComplete.txt04') }}</BaseButton>
    </div>
    <!-- // 버튼 -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseButton from '@/components/base/BaseButton.vue';

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 (탭) : 지원 완료
 */
export default {
  name: 'ProgramsApplyComplete',
  mixins: [
    mixinHelperUtils,
  ],

  components: {
    BaseButton,
  },

  props: {
    /**
     * Acc지원프로그램 관리 상세
     * @type {import('../dto/AccApplyPgmMngDetailDto').default}
     */
    accApplyPgmMngDetail: {
      type: Object,
      required: true,
    },
  },

  methods: {
    doNext() {
      this.$router.push({
        name: 'Home',
      });
    },
  },
};
</script>
